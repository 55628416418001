<template>
  <div class="page-item-wrap">
    <section class="sub-container">
      <search-aside
        :showFilter.sync="showFilter"
        :schRegion="schRegion"
        :schWorkPeriod="schWorkPeriod"
        :schBusiness="schBusiness"
        :schPosition="schPosition"
        :schPaymentMin="schPaymentMin"
        :schPaymentMax="schPaymentMax"
        @region="setRegion"
        @workPeriod="setWorkPeriod"
        @business="setBusiness"
        @position="setPosition"
        @payAmtMin="setPayAmtMin"
        @payAmtMax="setPayAmtMax"
        @search="onSearch"
      ></search-aside>
      <main class="sub-main">
        <search-bar
          @filterBtnClick="openFilter"
          @search="onSearch"
        ></search-bar>
        <main-job-swiper
          :sideSlides="false"
          :detailBtn="false"
          :list="bannerList"
        ></main-job-swiper>
        <special-job-list
          v-if="specialList && specialList.length"
          title="스페셜 채용"
          class="special"
          :list="specialList"
          showProductInfo
        ></special-job-list>
        <special-job-list
          v-if="urgentList && urgentList.length"
          title="긴급 채용"
          class="emergency"
          :list="urgentList"
          showProductInfo
        ></special-job-list>
        <normal-job-list
          :list="generalList"
          @search="searchGeneral"
        ></normal-job-list>
      </main>
    </section>
  </div>
</template>

<script>
import MainJobSwiper from 'Components/find/content/MainJobSwiper'
import SpecialJobList from 'Components/find/content/SpecialJobList'
import NormalJobList from 'Components/find/content/NormalJobList'
import SearchAside from 'Components/find/partials/SearchAside'
import SearchBar from 'Components/find/partials/SearchBar'
import findJobMixin from 'Mixins/findJob'

export default {
  components: {
    MainJobSwiper,
    SpecialJobList,
    NormalJobList,
    SearchAside,
    SearchBar,
  },
  mixins: [findJobMixin],
  methods: {
    openFilter() {
      this.showFilter = true
    },
    onSearch(schKeyword) {
      this.schKeyword = schKeyword
      this.fetchAllList()
    },
    searchGeneral(query) {
      // console.info('onSearch query : ', query)
      this.schRegion = query.schRegion
      this.schPosition = query.schPosition
      this.fetchGeneralList()
    },
    setRegion(localOnList) {
      this.schRegion = localOnList
    },
    setWorkPeriod(termOnList) {
      this.schWorkPeriod = termOnList
    },
    setBusiness(cateOnList) {
      this.schBusiness = cateOnList
    },
    setPosition(jobTypeOnList) {
      this.schPosition = jobTypeOnList
    },
    setPayAmtMin(payAmtMin) {
      this.schPaymentMin = payAmtMin
    },
    setPayAmtMax(payAmtMax) {
      this.schPaymentMax = payAmtMax
    },
  },
}
</script>

<style>
@media (min-width: 1024px) {
  main.sub-main .jobs-wrap.normal .inner .tbl-jobs td:nth-child(5) {
    /* padding-right: 18px; */
  }
}
</style>
