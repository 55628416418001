<template>
  <aside class="find-wrap" :class="{ on: showFilter }" id="findWrap">
    <div class="top-wrap">
      <h1>일자리 찾기</h1>
      <a
        href="javascript:void(0)"
        class="btn-prev"
        id="btnPrev"
        @click="closeFilter"
      >
        <i class="el-icon-arrow-left"></i>
      </a>
    </div>
    <div class="find-wrap-box">
      <h2 class="m">근무기간</h2>
      <div class="terms_list">
        <button
          v-for="(termItem, idx) in termList"
          :key="`ti_${idx}`"
          type="button"
          class="_term"
          :class="{ on: termItem.on }"
          @click="onClickTerm(termItem)"
        >
          {{ termItem.name }}
        </button>
      </div>
      <h2>지역</h2>
      <div class="local_list">
        <button
          v-for="(localItem, idx) in localList"
          :key="`li_${idx}`"
          type="button"
          class="btn _local"
          :class="{ on: localItem.on }"
          @click="onClickLocal(localItem)"
        >
          {{ localItem.name }}
        </button>
      </div>
      <h2>숙박업유형</h2>
      <div class="cate_list">
        <button
          v-for="(cateItem, idx) in cateList"
          :key="`ci_${idx}`"
          type="button"
          class="btn _cate"
          :class="{ on: cateItem.on }"
          @click="onClickCate(cateItem)"
        >
          {{ cateItem.name }}
        </button>
      </div>
      <h2>구인직종</h2>
      <div class="job_list">
        <button
          v-for="(jobTypeItem, idx) in jobTypeList"
          :key="`jti_${idx}`"
          type="button"
          class="btn _job"
          :class="{ on: jobTypeItem.on }"
          @click="onClickJobType(jobTypeItem)"
        >
          {{ jobTypeItem.name }}
        </button>
      </div>
      <h2>급료</h2>
      <div class="select_box">
        <select
          v-model="schPayAmtMin"
          name=""
          id=""
          class="select"
          @change="chnPayAmtMin"
        >
          <option value="">금액선택</option>
          <option value="80000">80,000</option>
          <option value="85000">85,000</option>
          <option value="90000">90,000</option>
          <option value="95000">95,000</option>
          <option value="100000">100,000</option>
          <option value="105000">105,000</option>
          <option value="110000">110,000</option>
          <option value="115000">115,000</option>
          <option value="120000">120,000</option>
        </select>
        <span>이상</span>
      </div>
      <div class="select_box">
        <select
          v-model="schPayAmtMax"
          name=""
          id=""
          class="select"
          @change="chnPayAmtMax"
        >
          <option value="">금액선택</option>
          <option value="80000">80,000</option>
          <option value="85000">85,000</option>
          <option value="90000">90,000</option>
          <option value="95000">95,000</option>
          <option value="100000">100,000</option>
          <option value="105000">105,000</option>
          <option value="110000">110,000</option>
          <option value="115000">115,000</option>
          <option value="120000">120,000</option>
        </select>
        <span>이하</span>
      </div>
      <!-- 검색 버튼 추가 -->
      <div class="btn_wrap">
        <button class="__search" @click="search">검색</button>
        <button class="__reset" @click="initSearch">초기화</button>
      </div>
    </div>
  </aside>
</template>

<script>
import {
  TERM_LIST,
  LOCAL_LIST,
  CATE_LIST,
  JOB_TYPE_LIST,
} from 'Configs/filter.js'

export default {
  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    schRegion: {
      type: String,
    },
    schWorkPeriod: {
      type: String,
    },
    schBusiness: {
      type: String,
    },
    schPosition: {
      type: String,
    },
    schPaymentMin: {
      type: String,
    },
    schPaymentMax: {
      type: String,
    },
  },
  data() {
    return {
      termList: JSON.parse(JSON.stringify(TERM_LIST)),
      localList: JSON.parse(JSON.stringify(LOCAL_LIST)),
      cateList: JSON.parse(JSON.stringify(CATE_LIST)),
      jobTypeList: JSON.parse(JSON.stringify(JOB_TYPE_LIST)),
      schPayAmtMin: '',
      schPayAmtMax: '',
    }
  },
  watch: {
    localOnList(newVal) {
      //모든게 다 눌린 경우 : 전체 저절로 클릭
      if (newVal.length === this.localList.length - 1) {
        this.localList[0].on = true
      } else if (newVal.length < this.localList.length - 1) {
        this.localList[0].on = false
      }
      this.$emit('region', newVal.map(item => item.name).join('/'))
    },
    termOnList(newVal) {
      this.$emit('workPeriod', newVal.map(item => item.value).join('/'))
    },
    cateOnList(newVal) {
      this.$emit('business', newVal.map(item => item.value).join('/'))
    },
    jobTypeOnList(newVal) {
      this.$emit('position', newVal.map(item => item.value).join('/'))
    },
    schRegion: {
      immediate: true,
      handler(newVal) {
        this.localList.forEach(local => this.$set(local, 'on', false))
        if (newVal) {
          newVal.split('/').forEach(item => {
            const target = this.localList.find(local => local.name === item)
            if (target) this.$set(target, 'on', true)
          })
        }
      },
    },
    schWorkPeriod: {
      immediate: true,
      handler(newVal) {
        this.termList.forEach(term => this.$set(term, 'on', false))
        if (newVal) {
          newVal.split('/').forEach(item => {
            const target = this.termList.find(term => term.value === item)
            if (target) this.$set(target, 'on', true)
          })
        }
      },
    },
    schBusiness: {
      immediate: true,
      handler(newVal) {
        this.cateList.forEach(busi => this.$set(busi, 'on', false))
        if (newVal) {
          newVal.split('/').forEach(item => {
            const target = this.cateList.find(busi => busi.value === item)
            if (target) this.$set(target, 'on', true)
          })
        }
      },
    },
    schPosition: {
      immediate: true,
      handler(newVal) {
        this.jobTypeList.forEach(position => this.$set(position, 'on', false))
        if (newVal) {
          newVal.split('/').forEach(item => {
            const target = this.jobTypeList.find(
              position => position.value === item,
            )
            if (target) this.$set(target, 'on', true)
          })
        }
      },
    },
    schPaymentMin: {
      immediate: true,
      handler(newVal) {
        this.schPayAmtMin = newVal
      },
    },
    schPaymentMax: {
      immediate: true,
      handler(newVal) {
        this.schPayAmtMax = newVal
      },
    },
  },
  computed: {
    //기간 목록
    termOnList() {
      return this.termList.filter(term => term.on === true)
    },
    //지역 목록
    localOnList() {
      return this.localList.filter(
        local => local.on === true && local.name !== '전체',
      )
    },
    //숙박업 유형 목록
    cateOnList() {
      return this.cateList.filter(cate => cate.on === true)
    },
    //구인직종 목록
    jobTypeOnList() {
      return this.jobTypeList.filter(jobType => jobType.on === true)
    },
  },
  methods: {
    // 검색하기
    search() {
      //
      this.$emit('search', this.$route.query.schKeyword)
      this.closeFilter()
    },
    // 검색어 초기화
    initSearch() {
      // 근무기간 초기화
      this.termList.forEach(term => {
        term.on = false
      })
      // 지역 초기화
      this.localList.forEach(local => {
        local.on = false
      })
      // 숙박업유형 초기화
      this.cateList.forEach(cate => {
        cate.on = false
      })
      // 구인직종 초기화
      this.jobTypeList.forEach(jobType => {
        jobType.on = false
      })
      // 급료 초기화
      this.schPayAmtMin = ''
      this.$emit('payAmtMin', this.schPayAmtMin)
      this.schPayAmtMax = ''
      this.$emit('payAmtMax', this.schPayAmtMax)
    },
    chnPayAmtMin() {
      this.$emit('payAmtMin', this.schPayAmtMin)
    },
    chnPayAmtMax() {
      this.$emit('payAmtMax', this.schPayAmtMax)
    },
    isLastOnItem(array) {
      if (array.length === 1) return true
      return false
    },

    closeFilter() {
      this.$emit('update:showFilter', false)
    },

    onClickTerm(termItem) {
      termItem.on = !termItem.on
    },
    onClickLocal(localItem) {
      if (localItem.name === '전체' && localItem.on)
        this.localList.forEach(localItem => (localItem.on = false))
      else if (localItem.name === '전체' && localItem.on === false)
        this.localList.forEach(localItem => (localItem.on = true))
      else localItem.on = !localItem.on
    },
    onClickCate(cateItem) {
      cateItem.on = !cateItem.on
    },
    onClickJobType(jobTypeItem) {
      jobTypeItem.on = !jobTypeItem.on
    },
  },
}
</script>

<style lang="scss" scoped>
// .find-wrap .find-wrap-box .btn {
//   margin-right: 10px;
//   &:nth-last-child() {
//     margin-right: 0px;
//   }
// }
.find-wrap .find-wrap-box {
  height: calc(100% - 61px - 16vw);
  overflow-y: auto;
}
</style>
