<template>
  <div>
    <div class="search_box">
      <!--select v-model="schCategory" name="" id="" class="select">
        <option value="">전체</option>
        <option value="place">근무처</option>
        <option value="title">근무내용</option>
      </!--select-->
      <input
        v-model="schKeyword"
        type="text"
        class="text"
        placeholder="근무처, 근무내용 검색"
        @keyup.enter="clickSearch"
      />
      <button class="_search" @click="clickSearch">
        <i class="el-icon-search"></i>
      </button>
    </div>
    <button class="_menu m" id="btnFilter" @click="filterBtnClick">
      <i class="el-icon-s-operation"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schCategory: '',
      schKeyword: '',
    }
  },
  watch: {
    '$route.query.schKeyword': {
      immediate: true,
      handler(newVal) {
        this.schKeyword = newVal
      },
    },
  },
  methods: {
    filterBtnClick() {
      this.$emit('filterBtnClick')
    },
    clickSearch() {
      const query = this.schKeyword ? { schKeyword: this.schKeyword } : null
      // alert(JSON.stringify(query))
      this.$router.push({
        name: 'findJob',
        query,
      })
      this.$emit('search', this.schKeyword)
      // this.$emit('search', query)
    },
  },
}
</script>

<style></style>
